import * as React from "react"
import Layout from '../components/Layout/Layout';
import CenteredSection from '../components/CenteredSection/CenteredSection';
import './RgpWidget.scss'

const RgpWidget = ({pageContext}) => {
  return (
    <Layout pageTitle={pageContext.title}>
      <CenteredSection></CenteredSection>
    </Layout>
  )
}

export default RgpWidget;